/* eslint-disable jsx-a11y/heading-has-content */

import "styles/pages/page-east-monuments.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TextWithSymbol from "components/TextWithSymbol";
import Breadcrumbs from "components/Breadcrumbs";

const EastMonumentsPage = ({ pageContext }) => {
	const pageEMonuments = pageContext.pageContent;
	return (
		<Layout headerColor="#FFCE2E">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={
					require("assets/images/eastmonuments-bg-main.jpg").default
				}
			/>
			<Breadcrumbs
				prevLocationLink="/zabytki"
				prevLocationLabel="Zabytki"
				currentLocationLabel="Zabytki Wschód"
			/>
			<section className="pattaya">
				<div className="container">
					<h2 className="section-title">
						{pageEMonuments.section1Title}
					</h2>
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageEMonuments.section1Subtitle}
								desc={pageEMonuments.section1Text}
								addres={pageEMonuments.section1Address}
								hours={pageEMonuments.section1Hours}
								phone={pageEMonuments.section1Phone}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageEMonuments.section1Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>
			<section className="nong-nooch">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageEMonuments.section3Title}
								desc={pageEMonuments.section3Text}
								addres={pageEMonuments.section3Address}
								hours={pageEMonuments.section3Hours}
								phone={pageEMonuments.section3Phone}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageEMonuments.section3Img?.sourceUrl}
								alt=""
								className="img-fluid nong-nooch__img"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="swiat-potworow">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageEMonuments.section4Img?.sourceUrl}
								alt=""
								className="img-fluid "
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageEMonuments.section4Title}
								desc={pageEMonuments.section4Text}
								addres={pageEMonuments.section4Address}
								hours={pageEMonuments.section4Hours}
								phone={pageEMonuments.section4Phone}
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="odwrocony-dom">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1 ">
							<TextWithSymbol
								title={pageEMonuments.section5Title}
								desc={pageEMonuments.section5Text}
								addres={pageEMonuments.section5Address}
								hours={pageEMonuments.section5Hours}
								phone={pageEMonuments.section5Phone}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageEMonuments.section5Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="chi-chan">
				<div className="container">
					<div className="row flex-xl-row flex-column-reverse">
						<div className="col-xl-6 text-center">
							<img
								src={pageEMonuments.section6Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
						<div className="col-xl-5 offset-xl-1">
							<TextWithSymbol
								title={pageEMonuments.section6Title}
								desc={pageEMonuments.section6Text}
								addres={pageEMonuments.section6Address}
								hours={pageEMonuments.section6Hours}
								phone={pageEMonuments.section6Phone}
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="wat-yang">
				<div className="container">
					<div className="row">
						<div className="col-xl-5 offset-xl-1 ">
							<TextWithSymbol
								title={pageEMonuments.title}
								desc={pageEMonuments.section7Text}
								addres={pageEMonuments.section7Address}
								hours={pageEMonuments.section7Hours}
								phone={pageEMonuments.section7Phone}
							/>
						</div>
						<div className="col-xl-5 offset-xl-1 text-center">
							<img
								src={pageEMonuments.section7Img?.sourceUrl}
								alt=""
								className="img-fluid"
							/>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default EastMonumentsPage;
